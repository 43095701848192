export function getRandomDuck(folderPath: string): string | null {
  try {
    const imageImports = import.meta.glob("../assets/ducks/*");
    const images: string[] = [];
    for (const path in imageImports) {
      if (path.includes("unknown.svg")) continue;
      images.push(path.replace("../assets", ""));
    }

    const duckImages = images.filter((image) => image.endsWith(".svg"));

    // Shuffle the array using the Fisher-Yates algorithm
    for (let i = duckImages.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [duckImages[i], duckImages[j]] = [duckImages[j], duckImages[i]];
    }

    const randomIndex = Math.floor(Math.random() * duckImages.length);
    const randomDuck = duckImages[randomIndex];

    console.log(duckImages);
    console.log(randomIndex);
    console.log(randomDuck);

    return randomDuck;
  } catch (error) {
    console.error("Error reading folder:", error.message);
    return null;
  }
}
