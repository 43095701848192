import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"
import moment from "moment";
import * as Sentry from "@sentry/browser";

const firebaseConfig = {
    apiKey: `${import.meta.env.VITE_apiKey}`,
    authDomain: `${import.meta.env.VITE_authDomain}`,
    projectId: `${import.meta.env.VITE_projectId}`,
    storageBucket: `${import.meta.env.VITE_storageBucket}`,
    messagingSenderId: `${import.meta.env.VITE_messagingSenderId}`,
    appId: `${import.meta.env.VITE_appId}`,
    measurementId: `${import.meta.env.VITE_measurementId}`,
};
  
firebase.initializeApp(firebaseConfig);
const firebaseImport = firebase;
const app = firebase.app();
const auth = firebase.auth();
const db = firebase.firestore();
const firebaseAuth = firebase.auth;
const functions = firebase.functions();
const storage = firebase.storage();
const storageRef = storage.ref();
const FirebaseFieldValue = firebase.firestore.FieldValue;
const FirebaseTimestamp = firebase.firestore.Timestamp;

Sentry.init({
  dsn: import.meta.env.NODE_ENV === 'development' ? 'https://5b746c4b18d3d62bbd42c963754fc310@o4506330372702208.ingest.sentry.io/4506334421516288' : "https://bf894d60308b3ec204aee45b8c837de3@o4506330372702208.ingest.us.sentry.io/4506697576087552",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: 'deiterate-prod@4.0.3',
  integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev-deiterate\.web\.app/,
    'riley.local',
    'macbook.local',
    /^https:\/\/admin\.deit\.dev/,
    /^https:\/\/admin\.deiterate\.com/,
    /^https:\/\/login\.deiterate\.com/,
    /^https:\/\/trust\.deiterate\.net/
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export { firebaseConfig, app, auth, db, firebaseImport, functions, storage, storageRef, FirebaseFieldValue, FirebaseTimestamp, firebaseAuth, moment }