// import "./style.css";
import { firebaseConfig, storageRef } from "./config/main_config.js";
import { getCompanyImg } from "./variableModules/downloadFromSDK.module.js";
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import Coloris from "@melloware/coloris";
import { getRandomDuck } from "./variableModules/getRandomDuck.ts";

const cloudFunctionURL = () => {
  if (
    (import.meta.env.PROD === true || import.meta.env.PROD === "true") &&
    !(
      window.location.host.includes("localhost") ||
      window.location.host.includes("127.0.0.1") ||
      window.location.host.includes(".local")
    )
  ) {
    return "https://australia-southeast1-policy-portal-d79d9.cloudfunctions.net/deiterateInternalAPI/trustCentre";
  } else {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:5001/dev-deiterate/australia-southeast1/deiterateInternalAPI/trustCentre";
    } else if (window.location.host.includes("127.0.0.1")) {
      return "http://127.0.0.1:5001/dev-deiterate/australia-southeast1/deiterateInternalAPI/trustCentre";
    }
  }
};

var actualPlaceholder = {};

window.trustCentre = null;

const loremIpsumWords = [
  "Lorem",
  "ipsum",
  "dolor",
  "sit",
  "amet",
  "consectetur",
  "adipiscing",
  "elit",
  "sed",
  "do",
  "eiusmod",
  "tempor",
  "incididunt",
  "ut",
  "labore",
  "et",
  "dolore",
  "magna",
  "aliqua",
  "Ut",
  "enim",
  "ad",
  "minim",
  "veniam",
  "quis",
  "nostrud",
  "exercitation",
  "ullamco",
  "laboris",
  "nisi",
  "ut",
  "aliquip",
  "ex",
  "ea",
  "commodo",
  "consequat",
  "Duis",
  "aute",
  "irure",
  "dolor",
  "in",
  "reprehenderit",
  "in",
  "voluptate",
  "velit",
  "esse",
  "cillum",
  "dolore",
  "eu",
  "fugiat",
  "nulla",
  "pariatur",
  "Excepteur",
  "sint",
  "occaecat",
  "cupidatat",
  "non",
  "proident",
  "sunt",
  "in",
  "culpa",
  "qui",
  "officia",
  "deserunt",
  "mollit",
  "anim",
  "id",
  "est",
  "laborum",
];

function getQueryParams() {
  const queryParams = {};
  const search = window.location.search.substring(1); // Get the query string without the leading '?'
  const params = search.split("&");

  for (const param of params) {
    const [key, value] = param.split("=");
    queryParams[key] = decodeURIComponent(value);
  }

  return queryParams;
}

// Function to add or update a query parameter in the current URL
function addQueryParam(key, value) {
  const queryParams = getQueryParams();
  queryParams[key] = value;

  const newQueryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  const newUrl = `${window.location.pathname}?${newQueryString}`;
  history.pushState({}, "", newUrl);
}

function saveMetadata(companyId, metadata) {
  const apiUrl = cloudFunctionURL();
  const apiKey = "public";
  const token = window.token;

  const requestBody = {
    apiKey: apiKey,
    companyID: companyId,
    method: "saveMetadata",
    content: {
      metadata: {
        ...(metadata.headerTextColor && {
          headerTextColor: metadata.headerTextColor,
        }),
        ...(metadata.headerColor && { headerColor: metadata.headerColor }),
      },
    },
    token: token,
  };

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => res.text())
    .then((data) => {
      console.log.bind(console);
      console.log(data);
    })
    .catch(console.error.bind(console));
}


window.validateToken = async () => {
  const apiUrl = cloudFunctionURL();
  const apiKey = "public";
  const token = window.token;

  const requestBody = {
    apiKey: apiKey,
    companyID: window.companyID,
    method: "validateToken",
    token: token,
  };

  return await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.ok) {
        console.log("Successfully validated token");
        return true;
      } else {
        console.log("Failed to validate token");
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

window.toggleSubLevelModule = function (
  element,
  module,
  submodule,
  companyId,
  type
) {
  if (type === "enable") {
    window.enableSubLevelModule(element, module, submodule, companyId);
    element.querySelector("input").setAttribute("checked", "checked");
    element.querySelector("span").textContent = "Enabled";
    element.style.opacity = "1";
    element.setAttribute(
      "onclick",
      `window.toggleSubLevelModule(this, '${module}', '${submodule}', '${companyId}', 'disable')`
    );
    return;
  } else {
    window.disableSubLevelModule(element, module, submodule, companyId);
    element.querySelector("input").removeAttribute("checked");
    element.querySelector("span").textContent = "Disabled";
    element.style.opacity = "0.5";
    element.setAttribute(
      "onclick",
      `window.toggleSubLevelModule(this, '${module}', '${submodule}', '${companyId}', 'enable')`
    );
    return;
  }
};

window.enableSubLevelModule = function (element, module, submodule, companyId) {
  const apiUrl = cloudFunctionURL();
  const apiKey = "public";
  const token = window.token;

  const requestBody = {
    apiKey: apiKey,
    companyID: companyId,
    method: "enableSubLevelModule",
    content: {
      module: module,
      submodule: submodule,
    },
    token: token,
  };

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      console.log.bind(console);
    })
    .catch(console.error.bind(console));
};

window.disableSubLevelModule = function (
  element,
  module,
  submodule,
  companyId
) {
  const apiUrl = cloudFunctionURL();
  const apiKey = "public";
  const token = window.token;

  const requestBody = {
    apiKey: apiKey,
    companyID: companyId,
    method: "disableSubLevelModule",
    content: {
      module: module,
      submodule: submodule,
    },
    token: token,
  };

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      console.log.bind(console);
    })
    .catch(console.error.bind(console));
};

// Function to remove a query parameter from the current URL
function removeQueryParam(key) {
  const queryParams = getQueryParams();
  delete queryParams[key];

  const newQueryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  const newUrl = `${window.location.pathname}?${newQueryString}`;
  history.pushState({}, "", newUrl);
}

// Function to generate a random sentence of a given length
function generateRandomSentence(length) {
  let sentence = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * loremIpsumWords.length);
    sentence += loremIpsumWords[randomIndex] + " ";
  }
  return sentence.trim(); // Remove trailing space
}

// Function to generate Lorem Ipsum paragraphs
function generateLoremIpsumParagraphs(numParagraphs, sentencesPerParagraph) {
  let loremIpsumText = "";
  for (let i = 0; i < numParagraphs; i++) {
    let paragraph = "";
    for (let j = 0; j < sentencesPerParagraph; j++) {
      paragraph += generateRandomSentence(5) + ". ";
    }
    loremIpsumText +=
      paragraph.charAt(0).toUpperCase() + paragraph.slice(1) + "\n\n";
  }
  return loremIpsumText.trim(); // Remove trailing newline
}

function replacePlaceholders(text, actualValues) {
  // Define a regular expression to match placeholders like {{ ... }}
  const placeholderRegex = /{{\s*(.*?)\s*}}/g;

  // Replace placeholders with their corresponding values from the database
  const replacedText = text.replace(placeholderRegex, (match, placeholder) => {
    // Remove spaces and convert to lowercase for the key in the database
    const key = placeholder.trim().toLowerCase().replace(/\s+/g, "_");

    // Check if the key exists in the database
    if (actualValues[key]) {
      // Check if the value is an array
      if (Array.isArray(actualValues[key])) {
        // If it's an array, generate HTML content for each element
        return actualValues[key]
          .map((item) => {
            return `<div class="array-item">${item}</div>`;
          })
          .join("");
      } else {
        // If it's not an array, return the value as is
        return actualValues[key];
      }
    } else {
      // If the key doesn't exist, return the original placeholder
      return match;
    }
  });

  return replacedText;
}

function replacePlaceholdersInDocument(actualValues) {
  const entireDocument = document.documentElement.outerHTML;
  const replacedDocument = replacePlaceholders(entireDocument, actualValues);

  // Replace the entire document's content
  const newDoc = new DOMParser().parseFromString(replacedDocument, "text/html");
  document.documentElement.innerHTML = newDoc.documentElement.innerHTML;
}

function loadFAQs(jsonData) {
  if (jsonData === undefined) {
    document.querySelector(".faqs").style.display = "none";
    return;
  };
  var template = `
<div class="faq">
  <div class="faq-image">
    <!-- <img src="{{ FAQ Image }}" alt="{{ FAQ Question }}" /> -->
    <i class="{{ FAQ Image }}" alt="{{ FAQ Question }}" aria-hidden="true"></i>
  </div>
  <div class="faq-question">
    <div class="faq-question-title">{{ FAQ Question }}</div>
  </div>
  <div class="faq-answer">
    <div class="faq-answer-text">
      {{ FAQ Answer }}
    </div>
  </div>
</div>
`;

  const container = document.querySelector(".faq-list");

  jsonData.forEach((item) => {
    // if (item.faq_answer === null) return; // Skip this question (reenable this in the future most likely)
    if (item.hasOwnProperty("enabled")) {
      if (item.enabled) {
        console.log(item);
        var faqItem = template
          .replaceAll(
            "{{ FAQ Image }}",
            item.faq_image !== undefined && item.faq_image !== ""
              ? item.faq_image.replaceAll("assets", "")
              : getRandomDuck("/ducks")
          ) // Replace with a default image if no image is provided
          .replaceAll("{{ FAQ Question }}", item.faq_question)
          .replaceAll(
            "{{ FAQ Answer }}",
            item.faq_answer || generateLoremIpsumParagraphs(2, 5)
          )
          .replaceAll(
            "Acme Inc",
            window.trustCentre.trustCentre.metadata.companyName
          ); // Replace with a default text if no answer is provided

        let div = document.createElement("div");
        div.innerHTML = faqItem;
        if (window.token !== undefined) {
          div
            .querySelector("div.faq")
            .setAttribute(
              "onclick",
              `window.toggleSubLevelModule(this, 'faqs', '${item.faq_question}', '${window.companyID}', 'disable')`
            );

          let enableDisableCheckBoxTemplate = `
          <div
              class="enable_disable"
              style="
                align-self: flex-end;
                justify-content: flex-end;
                display: flex;
                font-size: 1rem;
                opacity: 0.5;
                gap: 6px;
              "
            >
              <span>{{ STATE }}</span> <input {{ ISCHECKED }} type="checkbox" />
            </div>
            `

            let enableDisableCheckBox = enableDisableCheckBoxTemplate
              .replaceAll("{{ STATE }}", item.enabled? "Enabled" : "Disabled")
              .replaceAll("{{ ISCHECKED }}", item.enabled? "checked" : "")
              .replaceAll("Acme Inc", window.trustCentre.trustCentre.metadata.companyName);
            
            let edDiv = document.createElement("div");
            edDiv.style.display = "flex";
            edDiv.style.alignItems = "flex-end";
            edDiv.style.justifyContent = "flex-end";
            edDiv.style.alignSelf = "flex-end";
            edDiv.innerHTML = enableDisableCheckBox;
            div.querySelector("div.faq").prepend(edDiv);
            edDiv.querySelector("input").addEventListener("change", (e) => {
              if (e.target.checked) {
                window.toggleSubLevelModule(
                  div.querySelector("div.faq"),
                  "faqs",
                  item.faq_question,
                  window.companyID,
                  "enable"
                );
              } else {
                // div.querySelector("div.faq").style.opacity = "0.5";
                window.toggleSubLevelModule(
                  div.querySelector("div.faq"),
                  "faqs",
                  item.faq_question,
                  window.companyID,
                  "disable"
                );
              }
            });
        }
        container.appendChild(div.querySelector("div.faq"));
      } else {
        if (window.token !== undefined) {
          console.log(item);
          var faqItem = template
            .replaceAll(
              "{{ FAQ Image }}",
              item.faq_image !== undefined && item.faq_image !== ""
                ? item.faq_image.replaceAll("assets", "")
                : getRandomDuck("/ducks")
            ) // Replace with a default image if no image is provided
            .replaceAll("{{ FAQ Question }}", item.faq_question)
            .replaceAll(
              "{{ FAQ Answer }}",
              item.faq_answer || generateLoremIpsumParagraphs(2, 5)
            )
            .replaceAll(
              "Acme Inc",
              window.trustCentre.trustCentre.metadata.companyName
            ); // Replace with a default text if no answer is provided

          let div = document.createElement("div");
          div.innerHTML = faqItem;
          if (window.token !== undefined) {
            div
              .querySelector("div.faq")
              .setAttribute(
                "onclick",
                `window.toggleSubLevelModule(this, 'faqs', '${item.faq_question}', '${window.companyID}', 'enable')`
              );
            div.querySelector("div.faq").style.opacity = "0.5";
            let enableDisableCheckBoxTemplate = `
          <div
              class="enable_disable"
              style="
                align-self: flex-end;
                justify-content: flex-end;
                display: flex;
                font-size: 1rem;
                opacity: 0.5;
                gap: 6px;
              "
            >
              <span>{{ STATE }}</span> <input {{ ISCHECKED }} type="checkbox" />
            </div>
            `;

            let enableDisableCheckBox = enableDisableCheckBoxTemplate
              .replaceAll("{{ STATE }}", item.enabled ? "Enabled" : "Disabled")
              .replaceAll("{{ ISCHECKED }}", item.enabled ? "checked" : "")
              .replaceAll(
                "Acme Inc",
                window.trustCentre.trustCentre.metadata.companyName
              );

            let edDiv = document.createElement("div");
            edDiv.style.display = "flex";
            edDiv.style.alignItems = "flex-end";
            edDiv.style.justifyContent = "flex-end";
            edDiv.style.alignSelf = "flex-end";
            edDiv.innerHTML = enableDisableCheckBox;
            div.querySelector("div.faq").prepend(edDiv);

            edDiv.querySelector("input").addEventListener("change", (e) => {
              if (e.target.checked) {
                window.toggleSubLevelModule(
                  div.querySelector("div.faq"),
                  "faqs",
                  item.faq_question,
                  window.companyID,
                  "enable"
                )
              } else {
                // div.querySelector("div.faq").style.opacity = "0.5";
                window.toggleSubLevelModule(
                  div.querySelector("div.faq"),
                  "faqs",
                  item.faq_question,
                  window.companyID,
                  "disable"
                )
              }
            });
          }
          container.appendChild(div.querySelector("div.faq"));
          return;
        }
      }
    } else {
      if (window.token !== undefined) {
        var faqItem = template
          .replaceAll(
            "{{ FAQ Image }}",
            item.faq_image !== undefined && item.faq_image !== ""
              ? item.faq_image.replaceAll("assets", "")
              : getRandomDuck("/ducks")
          ) // Replace with a default image if no image is provided
          .replaceAll("{{ FAQ Question }}", item.faq_question)
          .replaceAll(
            "{{ FAQ Answer }}",
            item.faq_answer || generateLoremIpsumParagraphs(2, 5)
          )
          .replaceAll(
            "Acme Inc",
            window.trustCentre.trustCentre.metadata.companyName
          ); // Replace with a default text if no answer is provided

        let div = document.createElement("div");
        div.innerHTML = faqItem;

        div
          .querySelector("div.faq")
          .setAttribute(
            "onclick",
            `window.toggleSubLevelModule(this, 'faqs', '${item.faq_question}', '${window.companyID}', 'enable')`
          );
        div.querySelector("div.faq").style.opacity = "0.5";

        let enableDisableCheckBoxTemplate = `
          <div
              class="enable_disable"
              style="
                align-self: flex-end;
                justify-content: flex-end;
                display: flex;
                font-size: 1rem;
                opacity: 0.5;
                gap: 6px;
              "
            >
              <span>{{ STATE }}</span> <input {{ ISCHECKED }} type="checkbox" />
            </div>
            `;

        let enableDisableCheckBox = enableDisableCheckBoxTemplate
          .replaceAll("{{ STATE }}", item.enabled ? "Enabled" : "Disabled")
          .replaceAll("{{ ISCHECKED }}", item.enabled ? "checked" : "")
          .replaceAll(
            "Acme Inc",
            window.trustCentre.trustCentre.metadata.companyName
          );

        let edDiv = document.createElement("div");
        edDiv.style.display = "flex";
        edDiv.style.alignItems = "flex-end";
        edDiv.style.justifyContent = "flex-end";
        edDiv.style.alignSelf = "flex-end";
        edDiv.innerHTML = enableDisableCheckBox;
        div.querySelector("div.faq").prepend(edDiv);

        edDiv.querySelector("input").addEventListener("change", (e) => {
          if (e.target.checked) {
            window.toggleSubLevelModule(
              div.querySelector("div.faq"),
              "faqs",
              item.faq_question,
              window.companyID,
              "enable"
            );
          } else {
            // div.querySelector("div.faq").style.opacity = "0.5";
            window.toggleSubLevelModule(
              div.querySelector("div.faq"),
              "faqs",
              item.faq_question,
              window.companyID,
              "disable"
            );
          }
        });

        container.appendChild(div.querySelector("div.faq"));
        
      }
    }
  });
}

window.onload = async () => {
  var decrypted;
  console.log(
    "Is Production?",
    import.meta.env.PROD === true || import.meta.env.PROD === "true"
      ? "Yes"
      : "No"
  );
  if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("127.0.0.1") &&
      !window.location.host.includes(".local") &&
      !window.location.host.includes("trust.deit.dev") &&
      !window.location.host.includes("trust.deiterate.net")
  ) {
    console.log(window.location.host)
    var data = await dnsFetch();
    console.log(data);
    let encryptedCompanyID = data.txtRecordsData.Answer[0].data.replaceAll(
      '"',
      ""
    );
    console.log(encryptedCompanyID);
    decrypted = decrypt(encryptedCompanyID);
    console.log(decrypted);
  } else {
    decrypted = import.meta.env.VITE_COMPANY_ID;
  }

  window.companyID = decrypted;

  let queryParams = getQueryParams();

  if (queryParams.hasOwnProperty("token")) {
    console.log("Has Token, include token in requests.");

    window.token = queryParams["token"];
  
    let validateToken = await window.validateToken();

    if (validateToken === false) {
      window.token = undefined;
    } else {
    }
  }

  if (decrypted === "" || decrypted === undefined || decrypted === null) {
    document.querySelector("#errorScreen").style.display = "flex";
  }

  console.log(decrypted)
  console.log(window.companyID)

  var body = {
    apiKey: "public",
    companyID: decrypted,
    method: "loadTrustCentre",
  };

  if (window.token) {
    body.token = window.token;
  }

  await fetch(cloudFunctionURL(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      window.trustCentre = await res.json();
      console.log(trustCentre);

      actualPlaceholder["company_name"] =
        window.trustCentre.trustCentre.metadata.companyName;
      actualPlaceholder["company_logo"] =
        getCompanyImg(firebaseConfig, storageRef) ??
        window.trustCentre.trustCentre.metadata.companyLogo;

      actualPlaceholder["company_badges"] = [];
      loadFAQs(trustCentre?.trustCentre?.faqs?.modules);

      if (trustCentre?.trustCentre?.operationalData !== undefined) {
        let assetsColor = trustCentre?.trustCentre?.operationalData.assets.toLowerCase();
        let assuranceColor =
          trustCentre?.trustCentre?.operationalData.assurance.toLowerCase();
        let risksColor = trustCentre?.trustCentre?.operationalData.risks.toLowerCase();
        let assetDots = document.querySelectorAll(".assets");
        let assuranceDots = document.querySelectorAll(".assurance");
        let riskDots = document.querySelectorAll(".risks");

        assetDots.forEach((e) => {
          if (assetsColor === "green") {
            e.classList.add("color-green");
          } else if (assetsColor === "yellow" || assetsColor === "amber") {
            e.classList.add("color-amber");
          } else if (assetsColor === "red") {
            e.classList.add("color-red");
          }
        });

        riskDots.forEach((e) => {
          if (risksColor === "green") {
            e.classList.add("color-green");
          } else if (risksColor === "yellow" || risksColor === "amber") {
            e.classList.add("color-amber");
          } else if (risksColor === "red") {
            e.classList.add("color-red");
          }
        });

        assuranceDots.forEach((e) => {
          if (assuranceColor === "green") {
            e.classList.add("color-green");
          } else if (assuranceColor === "yellow" || assuranceColor === "amber") {
            e.classList.add("color-amber");
          } else if (assuranceColor === "red") {
            e.classList.add("color-red");
          }
        });
      }

      if (trustCentre.trustCentre.badges.enabled === true) {
        window.trustCentre.trustCentre.badges.modules.forEach((badge) => {
          if (badge.enabled === true) {
            if (window.token !== undefined) {
              actualPlaceholder["company_badges"].push(
                `<img src="${badge.module.replaceAll(
                  "/assets",
                  ""
                )}"  onclick="window.toggleSubLevelModule(this, 'badges', '${
                  badge.module
                }', '${window.companyID}', 'disable')" />`
              );
            } else {
              actualPlaceholder["company_badges"].push(
                `<img src="${badge.module.replaceAll("/assets", "")}"/>`
              );
            }
          } else {
            if (window.token !== undefined) {
              actualPlaceholder["company_badges"].push(
                `<img src="${badge.module.replaceAll(
                  "/assets",
                  ""
                )}"  onclick="window.toggleSubLevelModule(this, 'badges', '${
                  badge.module
                }', '${window.companyID}', 'enable')" style="opacity: 0.5;" />`
              );
            }
          }
        });
      } else {
        document.querySelector(".badges").remove();
      }

      if (trustCentre.trustCentre.operationalStatus.enabled === false) {
        document.querySelector(".realtimeStats").remove();
      }

      for (
        let i = 0;
        i < window.trustCentre.trustCentre.operationalStatus.modules.length;
        i++
      ) {
        if (
          window.trustCentre.trustCentre.operationalStatus.modules[i]
            .enabled === false
        ) {
          if (window.token !== undefined) {
            document
              .querySelector(
                `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                  i
                ].module
                  .replace(" Status", "")
                  .toLocaleLowerCase()}"]`
              )
              .setAttribute(
                "onclick",
                `window.toggleSubLevelModule(this, 'operationalStatus', '${trustCentre.trustCentre.operationalStatus.modules[i].module}', '${window.companyID}', 'enable')`
              );

            document.querySelector(
              `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                i
              ].module
                .replace(" Status", "")
                .toLocaleLowerCase()}"]`
            ).style.opacity = 0.5;

             document.querySelector(
               `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                 i
               ].module
                 .replace(" Status", "")
                 .toLocaleLowerCase()}"] .enable_disable input[type="checkbox"]`
             ).removeAttribute("checked");

             document.querySelector(
               `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                 i
               ].module
               .replace(" Status", "")
               .toLocaleLowerCase()}"] .enable_disable span`).textContent = "Disabled";
          } else {
            document
              .querySelector(
                `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                  i
                ].module
                  .replace(" Status", "")
                  .toLocaleLowerCase()}"] .enable_disable`
              )
              .remove();
            document
              .querySelector(
                `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                  i
                ].module
                  .replace(" Status", "")
                  .toLocaleLowerCase()}"]`
              )
              .remove();
          }
        } else {
          if (window.token !== undefined) {
            document
              .querySelector(
                `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                  i
                ].module
                  .replace(" Status", "")
                  .toLocaleLowerCase()}"]`
              )
              .setAttribute(
                "onclick",
                `window.toggleSubLevelModule(this, 'operationalStatus', '${trustCentre.trustCentre.operationalStatus.modules[i].module}', '${window.companyID}', 'disable')`
              );

              document
                .querySelector(
                  `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                    i
                  ].module
                    .replace(" Status", "")
                    .toLocaleLowerCase()}"] .enable_disable input[type="checkbox"]`
                )
                .setAttribute("checked", "checked");

              document.querySelector(
                `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                  i
                ].module
                  .replace(" Status", "")
                  .toLocaleLowerCase()}"] .enable_disable span`
              ).textContent = "Enabled";
          } else {
            document.querySelector(
              `[data-type="${trustCentre.trustCentre.operationalStatus.modules[
                i
              ].module
                .replace(" Status", "")
                .toLocaleLowerCase()}"] .enable_disable`
            ).remove();
          }
        }
      }

      if (trustCentre.trustCentre.faqs.enabled === false) {
        document.querySelector(".faq-list").remove();
      }

      if (trustCentre.trustCentre.metadata.hasOwnProperty("headerColor")) {
        console.log(
          "Setting header color to: ",
          window.trustCentre.trustCentre.metadata.headerColor
        );
        document.querySelector("nav").style.backgroundColor =
          window.trustCentre.trustCentre.metadata.headerColor;
      }

      if (trustCentre.trustCentre.metadata.hasOwnProperty("headerTextColor")) {
        console.log(
          "Setting header text color to: ",
          window.trustCentre.trustCentre.metadata.headerTextColor
        );
        document.querySelector("nav").style.color =
          window.trustCentre.trustCentre.metadata.headerTextColor;
          
      }

      
      

      replacePlaceholdersInDocument(actualPlaceholder);
    })
    .then((data) => {
      console.log(data);
      if (window.token !== undefined) {
        console.log(trustCentre.trustCentre.metadata.headerColor || "#585555");
        Coloris.init();
        Coloris.setInstance("#colorPicker", {
          theme: "pill",
          themeMode: "auto",
          formatToggle: true,
          closeButton: true,
          defaultColor:
            trustCentre.trustCentre.metadata.headerColor || "#585555",
          clearButton: true,
          swatches: [
            "#067bc2",
            "#84bcda",
            "#80e377",
            "#ecc30b",
            "#f37748",
            "#d56062",
          ],
          onChange: (color) => {
            document.querySelector("nav").style.backgroundColor = color;
          },
        });

        Coloris.setInstance("#textColorPicker", {
          theme: "pill",
          themeMode: "auto",
          formatToggle: true,
          closeButton: true,
          clearButton: true,
          defaultColor:
            trustCentre.trustCentre.metadata.headerTextColor || "#FFFFFF",
          swatches: [
            "#067bc2",
            "#84bcda",
            "#80e377",
            "#ecc30b",
            "#f37748",
            "#d56062",
          ],
          onChange: (color) => {
            document.querySelector("nav").style.color = color;
          },
        });

        // document.addEventListener("coloris:close", (event) => {
        //   console.log(event)
        //   // document.querySelector("nav").style.backgroundColor = event.detail.color;
        //   // saveMetadata(window.companyID, {
        //   //   headerColor: event.detail.color,
        //   // });
        // })

        document
          .querySelector(".bgColor")
          .addEventListener("close", (event) => {
            console.log("Saving header bg color", event.target.value);
            document.querySelector("nav").style.backgroundColor =
              event.target.value;
            saveMetadata(window.companyID, {
              headerColor: event.target.value,
            });
          });

        document
          .querySelector(".txtColor")
          .addEventListener("close", (event) => {
            console.log("Saving header text color", event.target.value);
            document.querySelector("nav").style.color = event.target.value;
            saveMetadata(window.companyID, {
              headerTextColor: event.target.value,
            });
          });
      } else {
        document.querySelectorAll(".color-pickers").forEach((picker) => {
          picker.remove();
        });
        document.querySelector(".navbar-items").style.width = 'auto';
      }
      document.querySelector("#companyImg").onload = function () {
        document.querySelector("#loadingScreen").remove();
      };
      console.log.bind(console);
    })
    .catch(console.error.bind(console));
};

const decrypt = (data) => {
  return CryptoJS.enc.Utf8.stringify(Base64.parse(data));
};

const dnsFetch = async () => {
  if (!window.location.host.includes("trust.kodo.tech")) {
    return await fetch(
      `https://cloudflare-dns.com/dns-query?name=_${window.location.host}&type=TXT`,
      {
        headers: {
          accept: "application/dns-json",
        },
      }
    )
      .then((res) => res.json())
      .then(async (txtRecordsData) => {
        return await fetch(
          `https://cloudflare-dns.com/dns-query?name=${window.location.host}&type=CNAME`,
          {
            headers: {
              accept: "application/dns-json",
            },
          }
        )
          .then((res) => res.json())
          .then(async (cnameRecordsData) => {
            return {
              txtRecordsData: txtRecordsData,
              cnameRecordsData: cnameRecordsData,
            };
            // if (txtRecordsData.Answer[0].name === "trust.deiterate.dev") {
            //   return {
            //     txtRecordsData: txtRecordsData,
            //     cnameRecordsData: cnameRecordsData,
            //   };
            // } else {
            //   throw new Error("Invalid Domain");
            // }
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        throw new Error(err);
      });
  } else {
    return await fetch(
      `https://cloudflare-dns.com/dns-query?name=${window.location.host}&type=TXT`,
      {
        headers: {
          accept: "application/dns-json",
        },
      }
    )
      .then((res) => res.json())
      .then(async (txtRecordsData) => {
        return await fetch(
          `https://cloudflare-dns.com/dns-query?name=${window.location.host}&type=CNAME`,
          {
            headers: {
              accept: "application/dns-json",
            },
          }
        )
          .then((res) => res.json())
          .then(async (cnameRecordsData) => {
            return {
              txtRecordsData: txtRecordsData,
              cnameRecordsData: cnameRecordsData,
            };
            // if (txtRecordsData.Answer[0].name === "trust.deiterate.dev") {
            //   return {
            //     txtRecordsData: txtRecordsData,
            //     cnameRecordsData: cnameRecordsData,
            //   };
            // } else {
            //   throw new Error("Invalid Domain");
            // }
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
};
